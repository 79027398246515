import "./_style.less";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  handleErrorLoadDataResponse,
  removeAllSymbol,
  useIsMounted,
} from "../../../@vendor/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { PaginationOptions } from "swiper/types/modules/pagination";
import { Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import VuiTitle from "../../../@vendor/components/atoms/Title";
import { VuiClientCard } from "../../../@vendor/components/atoms/Card";
import VuiMerchandiseCard from "../../../@vendor/components/atoms/Card/Merchandise";
import { SocialMediaWall } from "../../../components";
import {
  VuiContentLoading,
  VuiSecondaryButton,
} from "../../../@vendor/components";

import { Merchandise } from "../../../models/Merchandise";
import { Office } from "../../../models/Office";
import { Client } from "../../../models/Client";
import { Service } from "../../../models/Service";
import { Banner } from "../../../models/Banner";

import { IPageHome } from "../../../@vendor/utils/interfaces/page.interface";
import PageRepository from "../../../repositories/PageRepository";
import ServiceRepository from "../../../repositories/ServiceRepository";

const AppHomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const settings: any = useOutletContext();
  const [data, setData] = useState<IPageHome>({
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    title_about: "",
    description_about: "",
    image_about: "",
    button_label_about: "",
    button_link_about: "",
    title_social_media: "",
    title_client: "",
    title_office: "",
    title_merchandise: "",
    button_link_merchandise: "",
    banners: [],
    clients: [],
    instagram_link: "",
    youtube_link: "",
    linkedin_link: "",
    offices: [],
    merchandises: [],
  });

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [activeOffice, setActiveOffice] = useState<number>(0);
  const [merchandises, setMerchandises] = useState<Merchandise[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [activeService, setActiveService] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  const customPagination: PaginationOptions | boolean = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  const customRenderPagination = useCallback(
    (
      pagination: any,
      data: Banner[] | Service[] | Client[] | Office[] | Merchandise[] = [],
      minSlide: number = 1,
      divideNumber: number = 0
    ) => {
      if (pagination && data && data.length > minSlide) {
        const totalNumber =
          divideNumber > 0
            ? Math.ceil(data.length / divideNumber)
            : data.length;
        pagination.setAttribute("data-before", "01");
        pagination.setAttribute(
          "data-after",
          String((totalNumber < 10 ? "0" : "") + totalNumber)
        );
      }
    },
    []
  );

  const loadServices = useCallback(async () => {
    await ServiceRepository.findAll({
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setServices(responseData);
        }
      })
      .catch(() => {
        if (isMounted) {
          handleErrorLoadDataResponse(t, t("notification.error.default"));
        }
      });
  }, [t, isMounted]);

  const loadData = useCallback(async () => {
    await PageRepository.page("home")
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setBanners(responseData.banners);
          setClients(responseData.clients);
          setOffices(responseData.offices);
          setMerchandises(responseData.merchandises);
        }
      })
      .catch(() => {
        if (isMounted) {
          handleErrorLoadDataResponse(t, t("notification.error.default"));
        }
      });
  }, [isMounted, t]);

  const handleBuyLinkData = useCallback(
    (data: Merchandise) => {
      if (settings?.whatsapp_number) {
        const textMessage = `Halo Personal Growth, saya ingin bertanya mengenai ${data.name}`;
        return `https://wa.me/${removeAllSymbol(
          settings?.whatsapp_number
        )}?text=${encodeURI(textMessage)}`;
      }

      return "#";
    },
    [settings]
  );

  const renderBanner = useMemo(() => {
    if (isLoading) {
      return (
        <SwiperSlide key={0}>
          <div className="home-banner-item" style={{ background: "#EDEDED" }}>
            <VuiContentLoading loading />
          </div>
        </SwiperSlide>
      );
    }

    return banners.map((banner, index) => (
      <SwiperSlide key={index}>
        <div
          className={`home-banner-item ${
            banner.can_click ? "cursor-pointer" : ""
          }`}
          onClick={() =>
            banner.can_click
              ? window.location.replace(`${banner.button_link}`)
              : ""
          }
        >
          <div className="container">
            <img
              className="home-banner-item-image"
              src={width < 768 ? banner.image_mobile : banner.image}
              alt={`${index}`}
            />

            {(banner.title || banner.description) && (
              <>
                <div className="home-banner-item-content">
                  {banner.title && (
                    <h3 dangerouslySetInnerHTML={{ __html: banner.title }}></h3>
                  )}
                  {banner.description && (
                    <div
                      className="ant-typography"
                      dangerouslySetInnerHTML={{ __html: banner.description }}
                    ></div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </SwiperSlide>
    ));
  }, [banners, width, isLoading]);

  const renderOffice = useMemo(() => {
    return offices.map((office, index) => (
      <SwiperSlide key={index}>
        <div className="card-slider-office">
          <img src={office.image} alt={`${index}`} />
        </div>
      </SwiperSlide>
    ));
  }, [offices]);

  const renderService = useMemo(() => {
    return services.map((service, index) => (
      <SwiperSlide key={index}>
        <Link to={"/"}>
          <img src={service.photo?.url} alt={`${index}`} />
        </Link>
      </SwiperSlide>
    ));
  }, [services]);

  const renderClientDesktop = useMemo(() => {
    const dataPerRow: number = width > 768 && width < 1199 ? 8 : 10;
    let rows: any[] = [];
    for (let i = 0; i < Math.ceil(clients.length / dataPerRow); i++) {
      clients.forEach((client, index) => {
        if (index >= i * dataPerRow && index < (i + 1) * dataPerRow) {
          const findRow = rows.find((row) => row.id === i + 1);
          if (findRow) {
            findRow.data.push(client);
          } else {
            rows.push({
              id: i + 1,
              data: [client],
            });
          }
        }
      });
    }

    return rows.map((row, index) => (
      <SwiperSlide key={index} className="card-our-client-wrapper">
        {row.data.map((client: Client, index: number) => (
          <VuiClientCard client={client} key={index} />
        ))}
      </SwiperSlide>
    ));
  }, [clients, width]);

  const renderClientMobile = useMemo(() => {
    return clients.map((client, index: number) => (
      <SwiperSlide key={index}>
        <VuiClientCard client={client} />
      </SwiperSlide>
    ));
  }, [clients]);

  const renderMerchandiseDesktop = useMemo(() => {
    const dataPerRow: number = 4;
    let rows: any[] = [];
    for (let i = 0; i < Math.ceil(merchandises.length / dataPerRow); i++) {
      merchandises.forEach((merchandise, index) => {
        if (index >= i * dataPerRow && index < (i + 1) * dataPerRow) {
          const findRow = rows.find((row) => row.id === i + 1);
          if (findRow) {
            findRow.data.push(merchandise);
          } else {
            rows.push({
              id: i + 1,
              data: [merchandise],
            });
          }
        }
      });
    }

    return rows.map((row, index) => (
      <SwiperSlide key={index} className="card-our-merchandise-wrapper">
        {row.data.map((merchandise: Merchandise, index: number) => (
          <VuiMerchandiseCard
            key={index}
            data={merchandise}
            buyLink={handleBuyLinkData(merchandise)}
          />
        ))}
      </SwiperSlide>
    ));
  }, [merchandises, handleBuyLinkData]);

  const renderMerchandiseMobile = useMemo(() => {
    return merchandises.map((merchandise, index) => (
      <SwiperSlide key={index}>
        <VuiMerchandiseCard
          data={merchandise}
          buyLink={handleBuyLinkData(merchandise)}
        />
      </SwiperSlide>
    ));
  }, [merchandises, handleBuyLinkData]);

  useMemo(() => {
    (async () => {
      setIsLoading(true);
      await loadData();
      await loadServices();
      setIsLoading(false);
    })();
  }, [loadData, loadServices]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="app-home-page">
      <section className="home-banner">
        <Swiper
          loop={true}
          pagination={customPagination}
          onPaginationRender={(swiper, pagination) => {
            customRenderPagination(pagination, banners);
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
        >
          {renderBanner}
        </Swiper>
      </section>
      <section className="section home-about-us">
        <div className="container">
          <Row gutter={60}>
            <Col md={12} xs={24}>
              <img
                className="about-image"
                src={data.image_about}
                alt={data.title_about}
              />
            </Col>
            <Col md={12} xs={24}>
              <VuiTitle text={data.title_about} />
              <div
                className="ant-typography section-description"
                dangerouslySetInnerHTML={{ __html: data.description_about }}
              ></div>
              <VuiSecondaryButton
                onClick={() => window.location.replace(data.button_link_about)}
                label={data.button_label_about}
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className="section our-services">
        <div className="container">
          <Row gutter={60}>
            <Col className="our-services-information" md={11} xs={24}>
              <VuiTitle
                className="our-services-title-desktop"
                text={t("common.text.ourServices")}
              />

              {services.length > 0 ? (
                <Paragraph className="secondary-title">
                  {services[activeService].title}
                </Paragraph>
              ) : (
                ""
              )}

              {services.length > 0 ? (
                <Paragraph className="section-description">
                  {services[activeService].summary}
                </Paragraph>
              ) : (
                ""
              )}

              <VuiSecondaryButton
                onClick={() => {
                  navigate("/services");
                }}
                label={t("common.button.learnMore")}
              />
            </Col>
            <Col className="our-services-slider" md={13} xs={24}>
              <VuiTitle
                className="our-services-title-mobile"
                text={t("common.text.ourServices")}
              />

              <div>
                <Swiper
                  pagination={customPagination}
                  onPaginationRender={(swiper, pagination) => {
                    customRenderPagination(pagination, services);
                  }}
                  navigation
                  modules={[Pagination, Navigation]}
                  onSlideChange={(swiper) => {
                    setActiveService(swiper.activeIndex);
                  }}
                >
                  {renderService}
                </Swiper>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="section our-clients">
        <VuiTitle text={data.title_client} />
        <Swiper
          className="hide-mobile"
          navigation={true}
          pagination={customPagination}
          autoplay={true}
          onPaginationRender={(swiper, pagination) => {
            customRenderPagination(
              pagination,
              clients,
              width > 768 && width < 1199 ? 8 : 10,
              width > 768 && width < 1199 ? 8 : 10
            );
          }}
          modules={[Navigation, Pagination, Autoplay]}
        >
          {renderClientDesktop}
        </Swiper>

        <Swiper
          className="hide-desktop"
          navigation={true}
          autoplay={true}
          modules={[Navigation, Autoplay]}
        >
          {renderClientMobile}
        </Swiper>
      </section>

      <section className="section our-office">
        <div className="container">
          <Row gutter={60} align={"middle"}>
            <Col md={7} xs={24}>
              <VuiTitle text={data.title_office} />
              {offices.length > 0 ? (
                <div
                  className="ant-typography"
                  dangerouslySetInnerHTML={{
                    __html: offices[activeOffice].description,
                  }}
                ></div>
              ) : (
                ""
              )}
            </Col>
            <Col className="our-office-slider-column" md={17} xs={24}>
              <Swiper
                className={activeOffice > 0 ? "slider-change" : ""}
                slidesPerView={width <= 768 ? 1 : "auto"}
                centeredSlides={true}
                spaceBetween={15}
                navigation={true}
                autoplay={true}
                pagination={customPagination}
                onPaginationRender={(swiper, pagination) => {
                  customRenderPagination(pagination, offices);
                }}
                modules={[Navigation, Pagination, Autoplay]}
                onSlideChange={(swiper) => {
                  setActiveOffice(swiper.activeIndex);
                }}
              >
                {renderOffice}
              </Swiper>
            </Col>
          </Row>
        </div>
      </section>

      <section className="section our-merchandise">
        <VuiTitle text={data.title_merchandise} />
        <Swiper
          className="hide-mobile"
          navigation={true}
          pagination={customPagination}
          onPaginationRender={(swiper, pagination) => {
            customRenderPagination(pagination, merchandises, 4, 4);
          }}
          modules={[Navigation, Pagination]}
        >
          {renderMerchandiseDesktop}
        </Swiper>

        <Swiper
          className="hide-desktop"
          navigation={true}
          pagination={customPagination}
          onPaginationRender={(swiper, pagination) => {
            customRenderPagination(pagination, merchandises);
          }}
          modules={[Navigation, Pagination]}
        >
          {renderMerchandiseMobile}
        </Swiper>
      </section>

      {/*<section className="section social-media-wall">*/}
      {/*  <div className="container">*/}
      {/*    <SocialMediaWall />*/}
      {/*  </div>*/}
      {/*</section>*/}
    </div>
  );
};

export default AppHomePage;
